/***************************************************************************
 *
 * SCRIPT JS
 *
 ***************************************************************************/
$(document).on('turbolinks:load', function() {
    setTimeout("$('.notice').fadeOut('slow')", 2000);
});
$(document).on('turbolinks:load', function() {
    setTimeout("$('.alert').fadeOut('slow')", 2000);
});

$(document).on('turbolinks:load', function() {
    //data-hrefの属性を持つtrを選択しclassにclickableを付加
    $('tr[data-href]').addClass('clickable')
    .click(function(e) {
        if(!$(e.target).is('a')){
            window.location = $(e.target).closest('tr').data('href');}
    });
});
