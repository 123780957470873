import consumer from "./consumer"

const appRoom = consumer.subscriptions.create("ChatChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if ($('#messages').data('chat_room_id') === data['chat_room_id']) {
      $('#messages').append(data['comment']);
      return $('#chats').animate({ scrollTop: $('#chats')[0].scrollHeight }, 'fast');
    }
  },

  speak(comment){
    return this.perform('speak', {comment, chat_room_id: $('#messages').data('chat_room_id')});
  }
});

// 送信ボタンを押したら送信
$(document).on('click', '[data-behavior~=room_speaker]', function() {
  if ($('#comment').val() !== '') {
    appRoom.speak($('#comment').val());
    return $('#comment').val('');
  }
});

// エンター押したら送信
document.addEventListener('turbolinks:load', () => $(document).on('keypress', '#comment', function(e) {
  const $ta = $('#comment');
  if (e.keyCode === 13) {
    if (e.shiftKey) {
      $.noop();
    } else if ($ta.val().replace(/\s/g, '').length > 0) {
      e.preventDefault();
      if ($('#comment').val() !== '') {
        appRoom.speak($('#comment').val());
        $('#comment').val('');
      }
    }
  } else {
    $.noop();
  }
}));

// 画面の初回描写で最下層までスクロール
document.addEventListener('turbolinks:load', function() {
  if ($("#chats").length > 0) {
    return $('#chats').animate({ scrollTop: $('#chats')[0].scrollHeight }, 'fast');
  }
});